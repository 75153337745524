.filterMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;

  .recommend {
    padding: 4px 14px;
    display: flex;
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #FE7A15;
    background: var(--Linear, linear-gradient(270deg, #F76C4D 0%, #FE912C 100%));

    .recommendText {
      color: #fff;
      font-size: 13px;
      line-height: 22px;
    }
  }

  .freeSample {
    border-radius: 450px;
    border: 1px solid rgba(76, 110, 243, 0.40);
    background: #EDF0FE;
    padding: 7px 16px;
    font-size: 13px;
    line-height: 16px;
    color: #4E4E4E;
  }

  .freeSampleActive {
    background: #FDEFE9;
    color: #F0774A;
    border: 1px solid transparent;
  }

  .filterIcon {
    color: #6E7281;
    font-size: 22px;
  }

  .sortIcon {
    color: #6E7281;
    font-size: 26px;
  }

  .selected {
    color: #F0774A;
  }

  :global {
    .ant-drawer-body {
      margin-top: 116px;
    }
  }


}

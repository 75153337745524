.searchBox {
  padding: 0 12px;

  .infoSearch {
    border-radius: 8px;
    border: 1px solid #F76C4D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;

    .infoSearchLeft {
      font-size: 12px;
      display: flex;

      .searchType {
        color: #6E7281;
        padding: 0 12px;
        border-right: 1px solid #EDF0FE;
        line-height: 18px;
      }

      .searchTitle {
        color: var(--text-title);
        max-width: 180px;
        padding: 0 12px;
        line-height: 18px;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

    }

    .searchIcon {
      font-size: 16px;
      color: #6E7281;
    }
  }

  .noSearch {
    border-radius: 8px;
    border: 1px solid #EDF0FE;
    padding: 10px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .searchIcon {
      font-size: 16px;
      color: var(--text-disable);
    }

    .searchText {
      font-size: 12px;
      line-height: normal;
      color: var(--text-disable);
    }
  }
}

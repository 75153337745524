.productCard {
  border-radius: 10px;
  border: 1px solid #EDF0FE;
  background: #FFF;
  box-shadow: 0px 4px 7px 0px rgba(76, 110, 243, 0.10);
  // position: relative;
  min-height: 284px;

  .imageBox {
    width: 100%;
    /* 固定宽度 */
    height: 170px;
    /* 固定高度 */
    border-radius: 10px 10px 0px 0px;
    /* 圆角 */
    overflow: hidden;
    /* 超出部分隐藏 */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .image {
      // position: absolute;
      border-radius: 10px 10px 0px 0px;
      /* 根据最小边缩放 */
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -100%);
      width: auto;
      height: auto;
      min-width: 170px;
      /* 最小宽度缩放到170px */
      min-height: 170px;
      /* 最小高度缩放到170px */
    }

    .collectBox {
      width: 34px;
      height: 34px;
      background: #fff;
      clip-path: polygon(100% 0, 100% 100%, 0 0);
      border-top-right-radius: 10px;
      // z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: end;

      .collect {
        font-size: 16px;
        color: #A7A7A7;
        margin: 2px;
      }

      .collectActive {
        color: #F76C4D;
      }
    }

    .tipBox {
      position: absolute;
      top: 6px;
      left: 5px;
      display: flex;
      flex-wrap: wrap;
      max-width: 165px;


      .tip {
        padding: 2px 6px;
        border-radius: 16px 20px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        margin: 0 5px 5px 0;
      }
    }

    .mainInfo {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(13, 19, 44, 0.85);
      border-radius: 9px 0 0 0;
      padding: 2px 7px;

      // max-height: 37px;
      .title {
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        max-height: 28px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        /* 这里是超出几行省略 */
        overflow: hidden;
      }

      .price {
        font-size: 12px;
        line-height: 14px;
        color: #fff;
      }
    }
  }

  .contentEarn {
    margin: 10px 6px;

    .earnTitle {
      font-size: 16px;
      font-weight: 700;
      color: #F0774A;
      line-height: 18px;
    }

    .earnText {
      font-size: 12px;
      color: #B3B6C1;
      line-height: 14px;
      // margin-left: 5px;
    }
  }

  .earns {
    padding: 0 8px;
    display: flex;
    justify-content: space-between;

    .earnsWarp {
      display: flex;
      flex-direction: column;
      align-items: center;

      .earnsMin {
        display: flex;
        align-items: center;
        border-radius: 18px;
        background: #F3F4F6;
        padding: 2px 9px;
      }

      .earnsMax {
        display: flex;
        align-items: center;
        border-radius: 18px;
        background: #fee;
        padding: 2px 9px;
      }

      .earnsMaxDiff {
        background: #F3F4F6;
      }

      .earnNum {
        font-size: 12px;
        font-weight: 700;
        color: #454D6C;
        line-height: 14px;
      }

      .earnNumDiff {
        color: #6E7281;
        font-weight: 400;
      }

      .earnsMinIcon {
        font-size: 15px;
        color: #49B298;
        margin-left: 1px;
      }

      .earnsMaxIcon {
        font-size: 15px;
        color: #F4615E;
        margin-left: 1px;
      }

      .earnsTip {
        margin-top: 4px;
        color: #a7a7a7;
        font-size: 12px;
        line-height: 14px;
      }

    }

    .earnGo {
      font-size: 13px;
      color: #B3B3B3;
      margin-left: 4px;
      margin-top: 3px;
    }
  }

  .button {
    padding: 11px 6px;
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 每行 2 列 */
    gap: 11px;
    font-size: 12px;
    line-height: 14px;

    .sample {
      border-radius: 4px;
      background: #FDEFE9;
      color: #F0774A;

      padding: 4px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .add {
      border-radius: 4px;
      border: 1px solid #F0774A;
      background: linear-gradient(270deg, #F76C4D 0%, #FE912C 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 4px 0;
    }
  }

}

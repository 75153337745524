.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 99999;


  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .popup-enter {
    animation: slideUp 0.3s ease-out forwards;
  }

  .popup-exit {
    animation: slideDown 0.3s ease-in forwards;
  }

  .popup {
    background: var(--text-button);
    width: 100%;
    border-radius: 6px 6px 0 0;
    position: fixed;
    bottom: 0;
    // max-height: 454px;
    // overflow: scroll;

    .content {
      overflow: scroll;
      max-height: 400px;
      padding-bottom: 40px;
    }

    .header {
      padding: 16px 22px 24px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid var(--background2);

      .headerLeft {
        color: var(--text-title);
        font-size: 14px;
        font-weight: 700;
        line-height: 142%;
      }

      .headerRight {
        .headerRightIcon {
          font-size: 20px;
          color: var(--text-disable);
        }
      }
    }

    .productBox {
      display: flex;
      align-items: center;
      padding: 0 26px;

      .imageBox {
        width: 52px;
        height: 52px;
        border-radius: 9px;
        /* 圆角 */
        overflow: hidden;
        position: relative;
        flex-shrink: 0;
      }

      .image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        /* 根据最小边缩放 */
        min-width: 52px;
        /* 最小宽度缩放到 52px */
        min-height: 52px;
        /* 最小高度缩放到 52px */

      }

      .title {
        font-size: 12px;
        line-height: 14px;
        max-height: 28px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 这里是超出几行省略 */
        overflow: hidden;
        margin-left: 13px;
        flex-grow: 1;
      }
    }

    .info {
      padding: 23px 26px 20px 26px;

      ol {
        list-style-type: decimal;
        /* 确保有序列表使用数字 */
      }

      li {
        display: list-item;
        /* 确保 <li> 仍然被当作列表项渲染 */
      }

      .infoTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 142%;
        color: #000000;
      }

      .infoText {
        font-size: 14px;
        color: #454D6c;
        line-height: 150%;
      }

      .infoTextDiff {
        color: #f0774a;
        font-size: 14px;
        font-weight: 700;
        line-height: 150%;
      }

      ul {
        margin-left: 20px;
        list-style-type: disc;
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 25px 20px 25px;
      background: #fff;

      .footerBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
      }

      .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        line-height: 16px;
        background: var(--linear, linear-gradient(270deg, #F76C4D 0%, #FE912C 100%));
      }

      .cancel {
        border-radius: 4px;
        border: 1px solid #EDF0FE;
        background: #F4F6FE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        font-size: 14px;
        color: #4E4E4E;
        line-height: 16px;
      }

      .request {
        width: 243px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        border-radius: 4px;
        font-size: 14px;
        color: #fff;
        line-height: 16px;
        background: var(--linear, linear-gradient(270deg, #F76C4D 0%, #FE912C 100%));
      }
    }
  }

  .tipTextDiff {
    color: #FF6E1F;
    font-weight: 700;
  }
}

.homeFooter {
  background: #F3F4F6;
  padding: 19px 11px 7px 11px;

  .logo {
    width: 119px;
    height: 37px;
  }

  .title {
    color: #162047;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 20px 0 17px 1px;
  }

  .contactGroup {
    padding: 0px 0px 11px 3px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 每行 2 列 */
    gap: 20px;
    /* 设置间距 */

    .contactBox {
      display: flex;
      align-items: center;

      .iconBox {
        width: 24px;
        height: 24px;
        border-radius: 30px;
        margin-right: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .contactText {
        font-size: 12px;
        color: rgba(22, 32, 71, 0.76);
        line-height: 150%;
      }
    }
  }

  .QRGroup {
    display: flex;

    .QRBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 116px;
      margin-right: 5px;
    }

    .QR {
      width: 100px;
      height: 100px;
    }

    .text {
      font-size: 12px;
      line-height: 150%;
      color: #B3B6C1;
      text-align: center;
    }
  }

  .footerInfo {
    padding: 24px 1px 11px 1px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* 自动换行 */

    .text {
      color: #B3B6C1;
      font-size: 12px;
      line-height: 150%;
    }

    .textDiff {
      min-width: 130px;
      text-align: right;
    }
  }
}

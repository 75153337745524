.sortInfo {
  padding: 16px;

  .sortBox {
    margin: 14px 0 7px 0;
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    color: #0D132C;
  }

  .active {
    color: #F0774A;
    font-weight: 700;
  }
}

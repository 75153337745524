.filterInfo {
  .tab {
    flex: 1;
    display: flex;
    font-size: 14px;
    line-height: 18px;

    .tabCategory {
      flex: 1;
      border-bottom: 1px solid var(--background2);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #454D6C;
      padding: 9px 0;
    }

    .selected {
      color: #F0774A;
      font-weight: 700;
      border-bottom: 1px solid #F0774A;
    }
  }

  .content {
    max-height: 287px;
    overflow: scroll;
    padding: 22px 16px;

    .categoryList {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      .checkBox {
        font-size: 24px;
        padding-right: 7px;

        .unSelect {
          color: var(--text-disable);
        }

        .select {
          color: var(--primary);
        }

      }

      .categoryTitle {
        flex: 1;
        font-size: 12px;
        color: var(--text-title);
      }

      .categoryGo {
        font-size: 12px;
        color: var(--text-disable);
      }
    }

    .backGroup {
      display: flex;
      color: var(--text-secondary);
      font-size: 12px;

      .backIcon {
        margin-right: 6px;
        font-size: 14px;
      }

      .backText {
        line-height: 14px;
      }
    }
  }

  .commission {
    padding: 15px 16px 5px 16px;
    max-height: 287px;
    overflow: scroll;

    .title {
      font-size: var(--text-title);
      font-weight: 700;
      font-size: 12px;
      // margin-left: 8px;
    }

    .group {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .warp {
      width: 74px;
      margin-right: calc((100vw - 328px) / 3);
      margin-top: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--background3);
      border-radius: 3px;
      color: var(--text-title);
      font-size: 12px;
      line-height: 14px;
      padding: 3px 0;
    }

    .warp:nth-child(4) {
      margin-right: 0;
    }

    .active {
      background: #F0774A;
      color: var(--text-button);
    }
  }

}

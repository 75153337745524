.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .content {
    background: white;
    border-radius: 14px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .cancel {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .cancelIcon {
      font-size: 20px;
      color: var(--text-disable);
    }
  }

  .tipIcon {
    margin-top: 4px;
    font-size: 60px;
    color: var(--primary);
  }

  .loadingIcon {
    width: 36px;
  }

  .text {
    color: var(--text-title);
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 16px;

    .diffText {
      font-weight: 700;
    }
  }

  .wrap {
    margin-top: 16px;
    display: flex;
    width: 100%;
    // flex: 1;

    .leftBotton {
      flex: 1;
      display: flex;
      justify-content: center;
      border: 1px solid var(--text-title);
      color: var(--text-title);
      border-radius: 4px;
      padding: 8px 0;
      font-size: 14px;
      line-height: 16px;
      margin-right: 13px;
    }

    .rightBotton {
      padding: 8px 0;
      flex: 1;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 700;
      color: var(--text-button);
      background: var(--gradient-color);
      display: flex;
      justify-content: center;
    }
  }
}
